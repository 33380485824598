<template lang="pug">
b-modal#modal(lazy, ref='modal', centered, hide-footer, @hide='resetValues', size='xl')
  template(v-if='course')
    h2 Programme : {{ course.data.title1 }}
    p Les 3 premières vidéos du programme gratuites :
    .videos-wrapper 
      prismic-embed.videos-wrapper__video(
        v-for='video in courseFreeVideosSlice(course).primary',
        :key='video.title',
        :field='video'
      )
    common-button.access-button(
      label='Accéder au programme complet',
      variant='orange',
      isButton,
      @click='addCourseToCard'
    )

  template(v-else)
    h2 Choisissez le programme que vous souhaitez essayer gratuitement:
    p Vous aurez alors accès aux 3 premières vidéos du programme
    .cards-wrapper
      template(v-for='course in coursesToSelect')
        course-abstract-card(
          v-if='courseFreeVideosSlice(course)',
          :course='course',
          :key='course.id',
          @click='setCourse(course)',
          withShadow,
          simple
        )
</template>

<script>
import { BModal } from 'bootstrap-vue'

export default {
  name: 'FreeTrialModal',
  components: {
    BModal,
    CourseAbstractCard: () => import('@/components/CourseAbstractCard'),
    CommonButton: () => import('@/components/CommonButton')
  },
  props: {
    passedCourse: {
      type: Object,
      default: null
    },
    coursesToSelect: {
      type: Array,
      default: null
    }
  },
  data: () => {
    return {
      course: null
    }
  },
  mounted() {
    if (this.passedCourse) {
      this.course = this.passedCourse
    }
  },
  methods: {
    show() {
      this.$refs.modal.show()
    },
    addCourseToCard() {
      // We redirect the user on the program page if he's not on it
      this.$router.push({ name: 'CourseItem', params: { uid: this.course.uid } })
      this.$store.commit('ADD_ITEM_TO_CARD', this.course)
      this.$refs.modal.hide()
    },
    setCourse(course) {
      this.course = course
    },
    resetValues() {
      if (this.$route.name !== 'CourseItem') {
        this.course = null
      }
    },
    courseFreeVideosSlice(course) {
      return course.data.body.find((slice) => slice.slice_type === 'free_videos')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

h2 {
  font-weight: bold;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: $lightBlue;
}

.videos-wrapper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  &__video {
    width: 300px;
    height: 200px;
    margin-bottom: 1rem;

    &::v-deep {
      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.access-button {
  margin: auto;
  margin-top: 2rem;
}

.cards-wrapper {
  margin: -1rem;
  margin-top: 3rem;
  display: flex;
  flex-wrap: wrap;

  > * {
    flex: 1;
  }
}

::v-deep {
  .modal-body {
    padding: 0 3rem 3rem 3rem;
  }

  .modal-header {
    border-bottom: none;
  }
}
</style>
